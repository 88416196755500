import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
/* Theme Layout */
import './theme/layout.css';
/* App Icons */
import './theme/appicons.css';

// import './registerServiceWorker'

const config = {
  swipeBackEnabled: true,
  hardwareBackButton: true,
  navAnimation: null
}

const app = createApp(App)
  .use(IonicVue, config)
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
});

async function calendarAccess() {
  return new Promise((resolve, reject) => {
    // Check if we have permission to access the calendar
    window.plugins.calendar.hasReadWritePermission(
      (result) => {
        if (result === true) {
          // We already have permission
          console.log("We have calendar permissions.");
          resolve(true); // Resolve the promise with true, indicating permission is already granted
        } else {
          // We do not have permission, so let's request it
          window.plugins.calendar.requestReadWritePermission(
            () => {
              // Permission request was successful
              console.log("Calendar permissions granted.");
              resolve(true); // Resolve the promise with true, indicating permission is granted now
            },
            (err) => {
              // Permission request failed
              console.error("Calendar permissions were not granted:", err);
              reject(err); // Reject the promise with the error
            }
          );
        }
      },
      (err) => {
        // Error occurred while checking permissions
        console.error("Error checking calendar permissions:", err);
        reject(err); // Reject the promise with the error
      }
    );
  });
}

document.addEventListener('deviceready', async () => {
	try {
		await calendarAccess();
	} catch (error) {
		console.log({error});
	}
});