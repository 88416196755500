<template>
  <ion-header v-if="sharedState">
    <ion-toolbar color="light">
      <ion-title><img src="/assets/img/alfrEDU-logo-header.svg"></ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">
          <ion-icon :icon="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding policies-modal">
    <ion-list v-if="faqs" class="faq-list" type="list-inset">
      <ion-item-group v-for="faq in faqs" :key="faq.id">
        <ion-item 
          lines="full"
          class="ion-text-wrap"
          :class="isGroupShown(faq) ? 'active' : ''"
          :color="isGroupShown(faq) ? 'secondary' : 'tertiary'"
          @click="toggleGroup(faq)"
        >
          <ion-icon :icon="isGroupShown(faq) ? remove : add" slot="start" color="light"></ion-icon>
          <ion-label class="ion-text-wrap" color="light" v-html="faq.title || faq.question"></ion-label>
        </ion-item>
        <ion-item lines="full" v-if="isGroupShown(faq)">
          <p v-html="faq.text || faq.answer"></p>
        </ion-item>
      </ion-item-group>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonItem, IonItemGroup, IonList, IonLabel, modalController } from '@ionic/vue';
import { close, add, remove } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { store } from '@/store';

export default defineComponent({
  name: 'AboutModal',
  components: { IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonItem, IonItemGroup, IonList, IonLabel },
  props: {
    page: { type: String },
  },
  setup() {
    return {
      add,
      remove,
      close
    }
  },
  data() {
    return {
      shownGroup: null,
      faqs: null,
      sharedState: store.state,
    }
  },
  methods: {
    dismissModal: function() {
      modalController.dismiss();
    },
    isGroupShown: function(item) {
      return item === this.shownGroup;
    },
    toggleGroup: function(item) {
      this.shownGroup = this.shownGroup === item ? null : item;
    },
    getAboutData: async function() {
      this.faqs = await store.getAboutData();
    },
  },
  watch: {
    'sharedState.language': async function() {
      await this.getAboutData();
    },
    'sharedState.appData': {
      deep: true,
      async handler () {
        await this.getAboutData();
      }
    }
  },
  beforeMount: async function() {
    await this.getAboutData();
  },
  mounted: async function() {
    const lang = await store.getLanguage();
    const dir = (lang == 'ar' || lang == 'fa') ? 'rtl' : 'ltr';
    this.$el.parentNode.setAttribute('dir', dir);
  }
});
</script>

<style scoped>
  p { color: #666; }
</style>