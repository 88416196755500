import { Drivers, Storage } from '@ionic/storage';

const store = new Storage({
  driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
});

export const storage = {

  async setObject(key, value, version) {
    await store.set(key, JSON.stringify(value));
    await this.setItemMeta(key, version);
  },

  async setItem(key, value) {
    await store.set(key, value);
    await this.setItemMeta(key);
  },

  async setItemMeta(key, version) {
    let metaKey = `${key}_meta`;
    let metaValue = {
      time: Math.floor(Date.now()/1000),
      version: 0
    };
    if (version) metaValue.version = version;
    metaValue = JSON.stringify(metaValue);
    await store.set(metaKey, metaValue);
  },

  async getItem(key) {
    await store.create();
    const value = await store.get(key);
    return value;
  },

  async removeItem(key) {
    await store.remove(key);
    await this.removeItemMeta(key);
  },

  async removeItemMeta(key) {
    await store.remove(`${key}_meta`);
  },

  async keys() {
    const { keys } = await store.keys();
    return keys;
  },

  async clear() {
    await store.clear();
  },

  store: store
  
}