<template>
  <ion-header v-if="sharedState">
    <ion-toolbar color="light">
      <ion-title><img src="/assets/img/alfrEDU-logo-header.svg"></ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">
          <ion-icon :icon="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content v-if="sharedState" class="ion-padding policies-modal">
    <ion-grid>
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <h3 v-html="sharedState.ui.bookmarks.chooseColor"></h3>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-button @click="createBookmark('primary')" shape="round" fill="outline">
            <ion-icon :icon="bookmark" color="primary" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-button @click="createBookmark('secondary')" shape="round" fill="outline">
            <ion-icon :icon="bookmark" color="secondary" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-button @click="createBookmark('warning')" shape="round" fill="outline">
            <ion-icon :icon="bookmark" color="warning" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-button @click="createBookmark('royal')" shape="round" fill="outline">
            <ion-icon :icon="bookmark" color="royal" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-button @click="createBookmark('danger')" shape="round" fill="outline">
            <ion-icon :icon="bookmark" color="danger" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-button @click="createBookmark('dark')" shape="round" fill="outline">
            <ion-icon :icon="bookmark" color="dark" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-row>
      </ion-col>
    </ion-grid>
  </ion-content>
</template>

<script>
import { IonContent, IonGrid, IonCol, IonRow, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, modalController } from '@ionic/vue';
import { close, add, remove, bookmark } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { store } from '@/store';

export default defineComponent({
  name: 'BookmarksModal',
  components: { IonContent, IonGrid, IonCol, IonRow, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon },
  props: {
    page: { type: String },
  },
  setup() {
    return {
      add,
      remove,
      close,
      bookmark
    }
  },
  data() {
    return {
      shownGroup: null,
      faqs: null,
      sharedState: store.state,
    }
  },
  methods: {
    dismissModal() {
      modalController.dismiss();
    },
    isGroupShown: function(item) {
      return item === this.shownGroup;
    },
    toggleGroup: function(item) {
      this.shownGroup = this.shownGroup === item ? null : item;
    },
    getAboutData: async function() {
      this.faqs = await store.getAboutData();
    },
    async createBookmark(color) {
      await store.createBookmark(color);
      this.dismissModal();
    }
  },
  watch: {
    'sharedState.language': async function() {
      await this.getAboutData();
    },
    'sharedState.appData': {
      deep: true,
      async handler () {
        await this.getAboutData();
      }
    }
  },
  async beforeMount() {
    await this.getAboutData();
  },
  async mounted() {
    const lang = await store.getLanguage();
    const dir = (lang == 'ar' || lang == 'fa') ? 'rtl' : 'ltr';
    this.$el.parentNode.setAttribute('dir', dir);
  }
});
</script>

<style scoped>
[shape="round"] {
  width: 84px;
  height: 84px;
}
ion-col {
  flex-basis: auto;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
}
</style>