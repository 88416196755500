<template>
  <ion-icon :icon="icon" :color="color"></ion-icon>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { bookmark, bookmarkOutline } from 'ionicons/icons';
import { store } from '@/store';

export default  {
  name: 'BookmarkIcon',
  components: { IonIcon },
  setup() {
    return {
      bookmark,
      bookmarkOutline
    }
  },
  data() {
    return {
      sharedState: store.state,
      color: 'primary',
      icon: bookmarkOutline,
    }
  },
  methods: {
    checkIfBookmarksMatch: async function() {
      const bookmarks = await store.getBookmarks();
      if (!bookmarks) return;
      bookmarks.bookmarks.forEach(async item => {
        if (item.url === this.$route.fullPath && item.language === await store.getLanguage()) {
          this.color = item.color;
          this.icon = bookmark;
        }
      })
    }
  },
  mounted: async function() {
    this.checkIfBookmarksMatch();
  },
  watch: {
    'sharedState.bookmarks': {
      deep: true,
      handler: async function() { this.checkIfBookmarksMatch() }
    }
  }
}
</script>

